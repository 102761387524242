import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Avatar, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

import { useAuthContext } from '../../context/AuthContext';
import SearchBox from '../SearchBox';
import "./Header.css";
import Menu from "./Menu";
import logo from "../../assets/image/mintice_header.jpg"

function Header({isTop}) {  
  return (
    <div className='header'>
      <div className="header_icon">
        <Link to="/" className="app_icon">
          {!isTop && <img src={logo} className="header_icon" />}
        </Link>        
      </div>
      <nav className="header_nav">
        <ul className="header_links">
          <li className="header_link">
              <Link to="https://mintice.net" className="header_linkto">Mintice公式サイトへ</Link>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Header
