import React, { useState } from 'react';
import Modal from 'react-modal';

// Ensure to set app element for accessibility purposes
Modal.setAppElement('#root');

const ModalImage = ({ imgSrc }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  return (
    <div>
      <img src={imgSrc} onClick={openModal} style={{ cursor: 'pointer' }} />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
      >
        <button onClick={closeModal}>閉じる</button>
        <div>
          <img src={imgSrc} style={{ width: '90%' }} />
        </div>
      </Modal>
    </div>
  );
}

export default ModalImage;
