import React from 'react';
import Youtube from 'react-youtube';
import style from './YouTube.css';

function YouTube({url}) {

  //   const opts = {
  //       playerVars: {
  //       playsinline: 1,         //インライン再生
  //       autoplay: 1,            // 自動再生
  //       mute: 1,                //ミュート再生
  //       loop: 1,                //ループ再生
  //       width: '640',
  //       height: '360',
  //     }, 
  // }
    return (
      <Youtube 
      videoId= {url}
      // opts={opts}
      className={style.iframe} 
      containerClassName={style.youtube}
        />
    );
  }

  export default YouTube;