import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { Avatar, Button } from '@mui/material';

import './About.css';
import Header from "../components/header/Header";

const About = () => {
    console.log("無限ループチェック：About");


    return (
        <div className="about">
            {/* Header */}
            <Header />

            <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Frecord_planet_about%2FYuhei_The_background_image_of_a_beautiful_space_and_galaxy_twin_ad07f410-2eff-4a9e-b823-4ea63abe321c.jpg?alt=media&token=4b14c4b4-78f3-4915-8b85-292e866bc4a1" className="about_bg_image" />
            <img src="/logo512.png" className="about_header_logo" />

            <div className="about_text">
                <h2>
                    Minticeとは？
                </h2>
                <p>
                    私たちの人生には数え切れないほどの大切な瞬間や情報が存在しますが、時とともに記憶は薄れ、大切なことすら忘れてしまうことがあります。『Mint』とは、大切な思い出や情報を記録し、忘れないための全く新しいデジタルグッズです。
                </p>      
                <p>
                    『Mintice』は、『Mint』の販売・管理を行うための新しいマーケットプレイスです。
                </p>      
                <h2>
                    Minticeの役割
                </h2>          
                <p>
                    『Mintice』では、クリエイターのイベント・出来事に関連するデジタルグッズ『Mint』の販売・管理を支援します。音楽ライブイベントの盛り上げサポートから、イラストレーターの新オリジナルキャラクター作成記念のため等、様々な盛り上がりをサポートすることがMinticeの役割です。
                </p>
                <h2>
                    Minticeでやりたいこと
                </h2>     
                <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fconcept%2Fconcept_1.png?alt=media&token=93cfd1e0-ce5d-4768-909e-473ae4293f85" className="" />
                <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Fconcept%2Fconcept_2.png?alt=media&token=eebd368e-f367-4c60-86b6-656873fda0ad" className="" />
                <h3>
                    イベントの盛り上がり支援
                </h3>
                <p>
                    　Minticeのサービスを通じて、クリエイターのイベントがより盛り上がる支援をする。
                </p>
                <h3>
                    ファン＆クリエイターで作り上げた盛り上がりを記録として残す
                </h3>
                <p>
                    　SoulBoundTokens(SBTs)を用いることにより、改ざん・消去されにくい記録として、ファン＆クリエイターみんなで作り上げた盛り上がりを記録する。
                </p>
                <p>
                    これからの人生をより豊かにするために、ぜひ『Mintice』で、イベントをより楽しくしてください。あなたの大切な記録が、これからもいつまでも輝き続けることを願っています。
                </p>
            </div>

        </div>
    )
}
export default About