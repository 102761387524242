import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { db, auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"
import { useNavigate } from 'react-router-dom';
import './Complete.css';

import bg from "../assets/image/comp.png"

const Complete = () => {
  return (
    <div className='complete'>
      <div className='complete_message'>
        <p>登録完了ありがとうございます</p>
        <p>Mint作成の準備をしながら</p>
        <p>Minticeからのご連絡をお待ち下さい</p>
      </div> 
      <img className="complete_bg" src={bg}/>
    </div>
  );
};

export default Complete;