import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Avatar, Button } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { signOut } from "firebase/auth";
import { db, auth } from "../../firebase";
import { doc, addDoc } from 'firebase/firestore';

import { useAuthContext } from '../../context/AuthContext';
import SearchBox from '../SearchBox';
import "./Footer.css";

function Footer({mintInfo}) {
  const { me, myInfo } = useAuthContext();
  const handleAddMint = () => {
    addDoc(doc(db, "users", myInfo.userId, "cart", ), {
      
    }).then(() => {
      
    })
  };
  return (
    <div className="footer">
      {/* {mintInfo && myInfo && <div className="footer_btns">
          <Button variant="contained" className="btn" onClick={handleAddMint}><i className="cartitem cartitem-addwhite"></i>カートに入れる</Button>
      </div>}
      <nav className="nav">
          <ul className="nav_lists">
              <li className="nav_list nav_list-current"><a href="/"><i className="nav_icon nav_icon-store"></i>Store</a></li>
              <li className="nav_list"><a href="/mypage"><i className="nav_icon nav_icon-mybox"></i>My Box</a></li>
              <li className="nav_list"><a href="/creator/manage"><i className="nav_icon nav_icon-create"></i>Create</a></li>
              <li className="nav_list"><a href="#"><i className="nav_icon nav_icon-talk"></i>Talk</a></li>
              <li className="nav_list"><a href="#"><i className="nav_icon nav_icon-setting"></i>Setting</a></li>
          </ul>
      </nav> */}
    </div>
  )
}

export default Footer
