import { Button } from '@mui/material';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

import "./MailSent.css";

function MailSentFlow() {
  const params = useParams();
  return (
    <div className='mailsent'>
      <h1>メールをお送りいたしました。</h1>
      <p>メールに記載されたリンクをクリックして、登録を完了させてください。</p>
      <p>登録完了のあと、ボタンを押してください。</p>
      <Link to={"/events/" + params.eventId + "/" + params.tokenId + "/giftcode"}><Button variant='contained'>完了</Button></Link>
    </div>
  );
}

export default MailSentFlow;