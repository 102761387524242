import React, { useEffect, useState } from 'react'
import { useNavigate, Link } from "react-router-dom";
import { Avatar, Button } from '@mui/material';

import './LandingPage.css';

import header from "../assets/image/mintice_header.jpg"
import title from "../assets/image/main.jpg"
import block from "../assets/image/block.jpg"
import mybox from "../assets/image/mybox.jpg"
import mint1 from "../assets/image/mint1.png"
import mint2 from "../assets/image/mint2.png"
import mint3 from "../assets/image/mint3.png"
import mint4 from "../assets/image/mint4.png"
import mint5 from "../assets/image/mint5.png"
import mint6 from "../assets/image/mint6.png"
import manga1 from "../assets/image/manga1.jpg"
import manga2 from "../assets/image/manga2.jpg"
import manga3 from "../assets/image/manga3.jpg"
import hukidashi from "../assets/image/hukidashi.png"
import kami from "../assets/image/bg.png"
import appimage from "../assets/image/appimage.png"

const LandingPage = () => {              
    window.location.replace('https://mintice.net');
    const navigate = useNavigate();
    const handleTouroku = () => {
        navigate("/register");
    };
    return (
        <div className="lp">
            <div className="lp_header">
                <img src={header}/>
                <div>クリエイターとファンで熱狂できる</div>
                <div>デジタルグッズマーケット</div>
            </div>
            <div className="lp_title">
                <img src={title}/>
            </div>
            <div className="lp_caption">
                <div className="lp_caption_text">
                    <p><span className="lp_marker">あのときの熱量を</span></p>
                    <p><span className="lp_marker">そのままに</span></p>
                </div>
            </div>
            <div className="lp_message">
                <div>Mintice(ミンティス)は、楽しいイベントや大事な記念日の熱量でできたデジタルグッズ<span className="lp_green">”Mint”</span>が集まるマーケットプレイスです。</div>
                <div><span className="lp_green">Mint</span>は、Mintice上で簡単に作成・販売ができ、ファンの方の<span className="lp_red">ちょっといいかも！</span>の熱量で簡単に購入できます。</div>
            </div>
            <div className="lp_images">
                <img className="lp_mint1" src={mint1}/>
                <img className="lp_mint2" src={mint2}/>
            </div>
            <div className="lp_heading">
                <div><span className="lp_green">Mint</span>とは？</div>
            </div>
            <div className="lp_message">
                <div><span className="lp_green">Mint</span>は、複製・転売ができない、「所有」することができる新しいデジタルグッズです</div>
                <div>ファンが購入した<span className="lp_green">Mint</span>は、「SBTs」という強固なブロックチェーン技術によって守られており、購入者のみが「所有」できる特別なデジタルグッズです</div>
            </div>
            <div className="lp_image">
                <img className="lp_block" src={block}/> 
            </div>
            <div className="lp_message">
                <div><span className="lp_green">Mint</span>は購入したファンだけのもの。Minticeアプリに集めることで、推し活やファン交流を楽しむことができます</div>
            </div>
            <div className="lp_image">
                <img className="lp_mybox" src={mybox}/> 
            </div>
            <div className="lp_miryoku">
                <div className="lp_heading">
                    <div><span className="lp_green">Mint</span>の魅力</div>
                </div>
                <div className="lp_message">
                    <div><span className="lp_green">Mint</span>は実物のグッズの良さである「所有感」をしっかりと担保しつつ、実物のグッズが抱える様々な問題を解消することができます</div>
                </div>
                <div className="lp_item">
                    <div className="lp_item_header"><span className="lp_greenlight">表現の幅が広がる</span></div>
                    <div className="lp_item_underline"/>
                    <div className="lp_message">
                        <div><span className="lp_green">Mint</span>のみでメッセージ・画像・音声・動画といった幅広い表現方法をカバーできます。単一あるいは複数の表現方法を組み合わせて、これまでにない「グッズ」を作れます</div>
                    </div>
                </div>
                <div className="lp_item">
                    <div className="lp_item_header"><span className="lp_greenlight">期間限定販売で”一体感”を創出</span></div>
                    <div className="lp_item_underline"/>
                    <div className="lp_message">
                        <div><span className="lp_green">Mint</span>は、ライブ開催や作品公開といったイベントのタイミングで、期間限定の記念品として販売するデジタルグッズです。”今しか買えない”というライブ感や一体感を、ファンと一緒に楽しむことができます。</div>
                    </div>
                </div>
                <div className="lp_item">
                    <div className="lp_item_header"><span className="lp_greenlight">忙しいあなたの味方です</span></div>
                    <div className="lp_item_underline"/>
                    <div className="lp_message">
                        <div><span className="lp_green">Mint</span>はスマホ一台あれば用意が可能です。イベント当日、会場でその場で製造することも可能です</div>
                    </div>
                </div>
                <div className="lp_item">
                    <div className="lp_item_header"><span className="lp_greenlight">ファンの方がどんなグッズより気軽に買える</span></div>
                    <div className="lp_item_underline"/>
                    <div className="lp_message">
                        <div>よく知らなくても、列に並ばなくても、<span className="lp_red">ちょっといいかも！</span>の熱量でスマホ一台あれば購入できるグッズです</div>
                    </div>
                </div>
                <div className="lp_images">
                    <img className="lp_manga1" src={manga1}/>
                    <img className="lp_manga2" src={manga2}/>
                    <img className="lp_manga3" src={manga3}/>
                </div>
            </div>
            <div className="lp_pre_caption">
                <div className="lp_kuuhaku2" />
                <div>テストクリエイターを</div>
                <div>募集しています。</div>
            </div>            
            <div className="lp_pre_message">
                <div>Minticeは<span className="lp_red">正式サービス開始目前</span>です。</div>
                <div>ともに盛り上げてくださるクリエイターの方を募集しています。</div>
            </div>    
            <div className="lp_pre_title">
                募集期間
            </div>
            <div className="lp_message">
                <div>～ Mintice正式サービス開始まで</div>
                <div>※2023/10末を予定しております</div>
            </div>
            <div className="lp_kuuhaku1" />
            <div className="lp_pre_title">
                条件
            </div>
            <div className="lp_message">
                <div>・募集期間中に、下記ボタンから事前登録をしていただける方</div>
                <div className="lp_kuuhaku2" />
                <div>・<span className="lp_red">ユーザー注目度がとても高まる</span>Minticeのサービス開始のタイミングに合わせて<span className="lp_green">Mint</span>を販売してみよう！と考える方</div>
            </div>
            <div className="lp_image">
                <img className="lp_hukidashi" src={hukidashi}/> 
            </div>
            <div className="lp_footer">
                <div className="lp_kuuhaku2" />
                <img src={header}/>
            </div>
            <div className="lp_message">
                <div>Minticeではイラスト、音楽、文章など様々な作品を<span className="lp_green">Mint</span>として販売することが可能です。</div>
                <div>あなたの表現方法で<span className="lp_green">Mint</span>を作ってみよう！</div>
                <img src={appimage}/>
            </div>
            <div className="lp_touroku">
                <img className="lp_kami" src={kami} />
                <img className="lp_mint3" src={mint3} />
                <img className="lp_mint4" src={mint4} />
                <img className="lp_mint5" src={mint5} />
                <img className="lp_mint6" src={mint6} />
                <div className="lp_touroku_caption">さあ、始めよう！</div>
                <Button variant="contained" className="lp_touroku_button" onClick={handleTouroku}>事前登録はこちらから</Button>
            </div>
        </div>
    )
}
export default LandingPage