import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import MakeWallet from "./pages/MakeWallet";
import Top from "./pages/Top";
import About from "./pages/About";
import CreatorGuide from "./pages/creator/CreatorGuide";
import CreatorManage from "./pages/creator/CreatorManage";
import CreatorSelectType from "./pages/creator/CreatorSelectType";
import EventInfo from "./pages/events/EventInfo";
import TokenInfo from "./pages/events/TokenInfo";
import TokenInfoEx from "./pages/events/TokenInfoEx";
import GiftCode from "./pages/events/GiftCode";
import Chat from "./pages/events/Chat";
import NotFound from "./pages/NotFound";
import UserPage from "./pages/UserPage";
import SignUpFlow from "./pages/SignUpFlow";
import MakeWalletFlow from "./pages/MakeWalletFlow";
import LoginFlow from "./pages/LoginFlow";
import MailSent from "./pages/MailSent";
import MailSentFlow from "./pages/MailSentFlow";
import Terms from "./pages/Terms";
import Legal from "./pages/Legal";
import Payment from "./pages/Payment";
import LandingPage from "./pages/LandingPage";
import Register from "./pages/Register";
import LandingPageAM from "./pages/LandingPageAM";
import RegisterAM from "./pages/RegisterAM";
import Complete from "./pages/Complete";

const Routers = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/about" element={<About/>} />
                <Route path="/legal" element={<Legal/>} />

                <Route path="/" element={<LandingPage/>} />
                <Route path="/register" element={<Register/>} />
                <Route path="/complete" element={<Complete/>} />

                
                <Route path="/animaxmusix2023/" element={<LandingPageAM/>} />
                <Route path="/animaxmusix2023/register" element={<RegisterAM/>} />
                
                <Route path="*" element={<NotFound />}/>
            </Routes>
        </BrowserRouter>
    )
}
export default Routers