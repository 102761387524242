import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { db, auth } from '../firebase';
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification } from "firebase/auth";
import { doc, setDoc, serverTimestamp } from "firebase/firestore"
import { useNavigate } from 'react-router-dom';
import './Register.css';

const RegisterAM = () => {
  const navigate = useNavigate();
  const [creatorTypes, setCreatorTypes] = useState([
    { label: "ミュージシャン", checked: false },
    { label: "漫画家・イラストレーター", checked: false },
    { label: "デザイナー", checked: false },
    { label: "動画投稿・配信者", checked: false },
    { label: "イベンター", checked: false },
    { label: "インフルエンサー", checked: false },
    { label: "その他", checked: false },
  ]);
  const [typeFlag, setTypeFlag] = useState(false);
  const [error, setError] = useState('');
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [other, setOther] = useState("");
  const [sns, setSNS] = useState("");
  const [question, setQuestion] = useState("");

  const onSelectChange = (e) => {
    var flag = false;
    const newSelects = creatorTypes.map((creatorType) => {
      const newCreatorType = { ...creatorType };
      if (newCreatorType.label === e.target.value) {
        newCreatorType.checked = !newCreatorType.checked;
      }
      if (newCreatorType.checked) {
        flag = true;
      }
      return newCreatorType;
    });
    setCreatorTypes(newSelects);
    setTypeFlag(flag);
  }
  //アカウント作成
  const handleSubmit = async () => {
    try {
      if (name == "") {
        setError("クリエイター名を入力してください。");
      }
      else if (!typeFlag) {
        setError("クリエイタータイプを最低1つ選択してください。");
      }
      else if (email == "") {
        setError("メールアドレスを入力してください。");
      }
      else if (sns == "") {
        setError("SNSアカウントのURLを入力してください。");
      }
      else {
        await createUserWithEmailAndPassword(auth, email, "landingpage").then((userCredential) => {
          // Signed in 
          const user = userCredential.user;
  
          setDoc(doc(db, "users", user.uid), {
            accountName:name,
            userId: user.uid,
            type: "am2023",
            email: email,
            other: other,
            sns: sns,
            creatorType: creatorTypes,
            question: question,
            createdAt: serverTimestamp(),
            updatedAt: serverTimestamp()
          }).then(() => {
            sendEmailVerification(auth.currentUser)
              .then(() => {
                navigate("/complete");
              })
              .catch((error) => {
                // Handle Errors here.
              });
          });
        })
        .catch((error) => {
          switch (error.message) {
            case "Firebase: Error (auth/email-already-in-use).":
              setError("すでに登録されているメールアドレスです。");              
              break;
            
            case "Firebase: Error (auth/invalid-email).":
              setError("不正なメールアドレスです。");              
              break;
          
            default:
              setError(error.message);
              break;
          }
        });
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className='register_form'>
      <h2>
          ANIMAX MUSIX 2023応援Mint参加登録フォーム
      </h2>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <div className='register_item'>
        <h4>クリエイター名</h4>
        <input
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
      </div>
      <div className='register_item'>
        <h4>クリエイタータイプ</h4>
        <p>複数選択可</p>
        {creatorTypes.map((creatorType) => {
          return (
            <div key={creatorType.label}>
              <input
                id={creatorType.label}
                type="checkbox"
                value={creatorType.label}
                onChange={onSelectChange}
              />
              <label htmlFor={creatorType.label}>
                {creatorType.label}
              </label>
            </div>
          );
        })}
        <input
          value={other}
          onChange={(event) => setOther(event.target.value)}
        />
      </div>
      <div className='register_item'>
        <h4>メールアドレス</h4>
        <input
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </div>
      <div className='register_item'>
        <h4>SNSアカウント(最もご使用されているもの)</h4>
        <input
          value={sns}
          onChange={(event) => setSNS(event.target.value)}
        />
      </div>
      <div className='register_item'>
        <h4>その他質問等</h4>
        <textarea
          value={question}
          onChange={(event) => setQuestion(event.target.value)}
        />
      </div>
      
      <div className="register_buttonBox">
        <Button variant="contained" className="register_button" onClick={handleSubmit}>登録する</Button>
      </div>
    </div>
  );
};

export default RegisterAM;