import React, { useEffect, useState } from 'react'
import { useParams, Link } from "react-router-dom";
import { Avatar, Button } from '@mui/material';

import './Legal.css';
import Header from "../components/header/Header";

const Legal = () => {
    return (
        <div className="legal">
            {/* Header */}
            <Header />

            {/* <img src="https://firebasestorage.googleapis.com/v0/b/recordplanet-183e1.appspot.com/o/system%2Frecord_planet_legal%2FYuhei_The_background_image_of_a_beautiful_space_and_galaxy_twin_ad07f410-2eff-4a9e-b823-4ea63abe321c.jpg?alt=media&token=4b14c4b4-78f3-4915-8b85-292e866bc4a1" className="legal_bg_image" />
            <img src="/logo512.png" className="legal_header_logo" /> */}

            <div className="legal_text">
                <h2>
                    特定商取引法に基づく表記
                </h2>
                <h3>
                    事業者
                </h3>
                <p>
                    wizOnChain株式会社
                </p>      
                <p>
                    代表取締役　金下裕平
                </p>      
                <h3>
                    所在地
                </h3>          
                <p>
                    〒106-6115 東京都港区六本木六丁目10−1　六本木ヒルズ森タワー15F CIRCLE by ANRI
                </p>
                <h3>
                    お問合せ先
                </h3>     
                <p>
                    メールアドレス：　info@wizonchain.com
                </p>     
                <p>
                    電話番号：　070-4802-5495（受付時間10:00～18:00（土日祝日を除く。））
                </p>
                <h3>
                    ウェブサイト
                </h3>
                <p>
                    URL：　<a href='https://www.wizonchain.com/'>https://www.wizonchain.com/</a>
                </p>
                <h3>
                    販売価額
                </h3>
                <p>
                    購入手続きの際に画面に表示されます。販売価格は、各商品に表記された価格に準じます。商品の出品者には、規定の手数料が売買成立時にかかります。
                </p>                
                <h3>
                    商品代金以外に必要な費用
                </h3>
                <p>
                    情報端末の購入費、インターネット接続料金、通信料金等の実費がかかります（金額はお客様が契約した各事業者が定める通りとします。）。
                </p>                  
                {/* <p>
                    商品の配送が生じる場合には商品の配送料が発生する場合があります。配送料の金額については、お客様と商品の売主であるクリエイターとの契約に定める通りとします。
                </p>*/}
                <h3>
                    支払時期と支払方法
                </h3>
                <p>
                    支払時期：　クレジットカードの場合には直ちに処理されますが、国内の銀行振り込みの場合には購入から3営業日以内にお振込みをいただく必要があります。
                </p>
                <p>
                    支払方法：　クレジットカードまたは国内の銀行振り込みその他当社が別途指定する方法
                </p>              
                <h3>
                    商品の引き渡し時期
                </h3>
                <p>
                    代金の支払いその他必要な手続として当社が予め明示するものを実施した後、利用可能となります。
                </p>
                {/* <p>
                    商品の配送が生じる場合には、購入後1～10営業日以内に処理され、商品は、30日以内に配送されます。
                </p>*/}
                <h3>
                    返品・キャンセル等に関する特約
                </h3>
                <p>
                    商品の特性上、返品・キャンセル等購入後の返品や交換、キャンセルは一切受け付けておりません。
                </p>
            </div>

        </div>
    )
}
export default Legal