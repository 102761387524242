import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAZLJMYpapP8AroLRFAqzeGQN1WqNgFolE",
    authDomain: "mintice-lp.firebaseapp.com",
    projectId: "mintice-lp",
    storageBucket: "mintice-lp.appspot.com",
    messagingSenderId: "203551787018",
    appId: "1:203551787018:web:baf34b484fa8593149f7f3",
    measurementId: "G-17EP97J80D"
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// Initialize Cloud Realtime Database and get a reference to the service
export const database = getDatabase(app);

// get auth
export const auth = getAuth(app);

// Initialize Cloud Storage and get at reference to the service
export const storage = getStorage(app);