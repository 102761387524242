import React from 'react';
import ReactDOM from 'react-dom/client';
// import {ChainId, ThirdwebProvider} from "@thirdweb-dev/react";
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <ThirdwebProvider desiredChainId={ChainId.Mumbai}> */}
      <App />  
    {/* </ThirdwebProvider> */}
  </React.StrictMode>
);