import './App.css';
import Routers from './Routers';
import { AuthProvider } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <div className="app">
        <Routers />
      </div>
    </AuthProvider>
  );
}

export default App;
